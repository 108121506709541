import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isOpen", "onClose", "projectId"];
var __jsx = React.createElement;
import { ModalCloseButton } from '@chakra-ui/react';
import { FlexModalContent, Modal, ModalBody, ModalOverlay, isSmallScreen } from '@playful/design_system';
import React from 'react';
import { PROJECT_SETTINGS_MAX_WIDTH, ProjectSettings as ProjectSettingsForm } from './ProjectSettings';
export function ProjectSettingsModal(_ref) {
  var isOpen = _ref.isOpen,
    onClose = _ref.onClose,
    projectId = _ref.projectId,
    props = _objectWithoutProperties(_ref, _excluded);
  var isSmScreen = isSmallScreen();
  return __jsx(Modal, {
    isOpen: isOpen,
    onClose: onClose,
    scrollBehavior: 'inside'
  }, __jsx(ModalOverlay, null), __jsx(FlexModalContent, {
    m: 4,
    modalPos: isSmScreen ? 'bottom' : 'top',
    maxW: PROJECT_SETTINGS_MAX_WIDTH
  }, __jsx(ModalCloseButton, {
    variant: 'ghost',
    backgroundColor: 'transparent',
    _hover: {
      backgroundColor: 'gray.400'
    },
    right: 2,
    "aria-label": "Close project settings"
  }), __jsx(ModalBody, {
    p: 0
  }, __jsx(ProjectSettingsForm, _extends({
    isOpen: isOpen,
    projectId: projectId,
    w: "full",
    onClose: onClose
  }, props)))));
}