import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["info"],
  _excluded2 = ["info"];
var __jsx = React.createElement;
import { hasPrivateContent } from '@playful/runtime';
import React from 'react';
import { Box, Text } from '@playful/design_system';
import { forwardRef } from '@chakra-ui/react';
export var UserProjectCardContainer = forwardRef(function (_ref, ref) {
  var info = _ref.info,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Box, _extends({
    ref: ref,
    "data-cy-primary-action": "edit-project",
    "data-cy": "project-card",
    "data-cy-published": !!info.published,
    "data-cy-owner": info.owner,
    "data-cy-title": info.title,
    "data-cy-id": info.id,
    "data-private": hasPrivateContent(info) ? true : undefined,
    display: "grid",
    gridTemplateAreas: "'card'",
    userSelect: 'none',
    role: 'group'
  }, props));
});

// bit of a tricky layout. we want the entire card to be a link, but the overlay with the button only
// on the thumbnail. rather than nesting buttons in links and dealing with event bubbling, we can do
// this with css grid overlays and pointer events, along with focus-within at a group level.
export function UserProjectCardBody(_ref2) {
  var info = _ref2.info,
    props = _objectWithoutProperties(_ref2, _excluded2);
  return __jsx(Box, _extends({
    gridArea: 'card',
    flexDir: 'column',
    gap: 4,
    display: "flex",
    pointerEvents: 'none',
    minW: 0
  }, props));
}
export function UserProjectText(props) {
  return __jsx(Text, _extends({
    color: 'gray.800',
    lineHeight: 'shorter',
    fontWeight: 500,
    transitionDuration: '360ms',
    transitionProperty: 'color',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    fontSize: 'sm'
  }, props));
}