var __jsx = React.createElement;
import { Skeleton } from '@chakra-ui/react';
import { Box, MotionBox, Tab, TabList, Tabs } from '@playful/design_system';
import React from 'react';
import { formatEventString, triggerCustomEvent } from '@playful/telemetry';
export function TaggedProjectsHeader(_ref) {
  var categories = _ref.categories,
    wrap = _ref.wrap,
    isLoading = _ref.isLoading;
  return __jsx(MotionBox, {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1
    },
    transition: {
      type: 'spring',
      mass: 1,
      stiffness: 100,
      damping: 35
    }
  }, isLoading ? __jsx(Box, {
    display: "flex",
    gap: 3,
    h: "60px",
    alignItems: "center"
  }, categories.map(function (c) {
    return __jsx(Skeleton, {
      key: c.title,
      height: "14px",
      width: "74px"
    });
  })) : __jsx(TabList, {
    position: 'static',
    left: "0",
    zIndex: 'auto',
    pb: 6,
    overflowX: "auto",
    flexWrap: wrap ? 'wrap' : 'nowrap'
  }, categories.map(function (c) {
    return __jsx(Tab, {
      key: c.title,
      whiteSpace: "nowrap",
      onClick: triggerCustomEvent('templatepicker-label-click', {
        name: formatEventString(c.title)
      })
    }, c.title);
  })));
}
export function TaggedProjectsTabs(_ref2) {
  var onTabChange = _ref2.onTabChange,
    tabIndex = _ref2.tabIndex,
    children = _ref2.children;
  return __jsx(Tabs, {
    size: 'sm',
    onChange: function onChange(index) {
      return onTabChange(index);
    },
    variant: "solid",
    index: tabIndex,
    isLazy: true
  }, children);
}