var __jsx = React.createElement;
import { Modal, ModalOverlay, ModalBody, ModalContent, ModalFooter, ModalHeader, Button } from '@playful/design_system';
import { useUserProjects } from '../../hooks/useProjects.js';
import React from 'react';
import { useBulkMode } from './BulkEditMode.jsx';
import { CTAButton } from '../CallToAction/CTAButton.jsx';
import { useUserContext } from '../../user/UserContext.jsx';
export function BulkModeDeleteModal(_ref) {
  var isOpen = _ref.isOpen,
    onClose = _ref.onClose;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user;
  var _useUserProjects = useUserProjects(user.id, undefined, {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: false
    }),
    deleteProject = _useUserProjects.deleteProject;
  var _useBulkMode = useBulkMode(),
    selectedInfos = _useBulkMode.selectedInfos,
    setSelectedInfos = _useBulkMode.setSelectedInfos;
  return __jsx(Modal, {
    isOpen: isOpen,
    onClose: onClose,
    isCentered: true
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, {
    maxWidth: '460px',
    mx: 4
  }, __jsx(ModalHeader, {
    pt: 6
  }, "Delete these projects forever?"), __jsx(ModalBody, {
    fontWeight: 'normal',
    fontSize: '1rem'
  }, "If you delete these projects they will be gone forever. If any of these projects have been published, any links to them will break. They will be removed from the gallery and your profile."), __jsx(ModalFooter, {
    gap: 4,
    pb: 6
  }, __jsx(Button, {
    variant: 'outline',
    w: '100%',
    onClick: onClose,
    borderColor: 'gray.200'
  }, "cancel"), __jsx(CTAButton, {
    colorScheme: "red",
    w: '100%',
    onClick: function onClick() {
      selectedInfos.forEach(function (id) {
        return deleteProject(id);
      });
      setSelectedInfos(new Set());
      onClose();
    }
  }, "delete them"))));
}