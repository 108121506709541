import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useProject } from './useProject';
import { useRouter } from './useRouter.js';
export function useThumbLinkQuery(_ref) {
  var projectId = _ref.projectId,
    fallbackData = _ref.fallbackData;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  if (!projectInfo) return;
  var query = {
    playerModal: true,
    from: asPath,
    publishedUrl: '',
    projectId: '',
    userName: '',
    slug: ''
  };

  // If the project has a published URL that means it uses a custom domain
  if (projectInfo.publishedUrl) {
    query = _objectSpread(_objectSpread({}, query), {}, {
      publishedUrl: projectInfo.publishedUrl,
      projectId: projectInfo.id
    });
  } else {
    query = _objectSpread(_objectSpread({}, query), {}, {
      userName: projectInfo.ownerName,
      slug: projectInfo.slug
    });
  }
  return query;
}