import { useProjectPreview } from '../explorer/ProjectPreview.jsx';
import { useProject } from './useProject';
export function useProjectThumb(_ref) {
  var projectId = _ref.projectId,
    videoUrl = _ref.videoUrl,
    showStaticImage = _ref.showStaticImage,
    fallbackData = _ref.projectInfo,
    showPublished = _ref.showPublished;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  var _useProjectPreview = useProjectPreview({
      projectInfo: projectInfo,
      showPublished: showPublished
    }),
    _useProjectPreview$pr = _useProjectPreview.previewUrl,
    previewUrl = _useProjectPreview$pr === void 0 ? '' : _useProjectPreview$pr;
  var shouldUseVideo = videoUrl && !showStaticImage;
  return {
    type: shouldUseVideo ? 'video' : 'image',
    mediaUrl: shouldUseVideo ? videoUrl : previewUrl,
    poster: shouldUseVideo ? previewUrl : undefined
  };
}