import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { createGenericContext } from '@playful/utils';
import React, { useCallback, useState } from 'react';
import { useGroupDisclosure } from '@playful/design_system';
import { ProjectSettingsModal } from '../../projectSettings/ProjectSettingsModal.jsx';
import { RenameProjectModal } from '../../projectSettings/RenameProjectModal.jsx';
import { ConfirmDeleteDialog } from '../../explorer/ConfirmDeleteDialog.jsx';
import { VersionsDialog } from '../../explorer/VersionsDialog.jsx';
import { TagsDialog } from '../../explorer/TagsDialog.jsx';
import { addErrorToast, addSuccessToast } from '../../workbench/components/AppToasts.jsx';
import { useUserContext } from '../../user/UserContext.jsx';
import { FEATURE_PROJECT_TAGS } from '@playful/api';
var _createGenericContext = createGenericContext({
    displayName: 'ProjectMenu'
  }),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useHook = _createGenericContext2[0],
  UserProjectProvider = _createGenericContext2[1];
export var useUserProject = useHook;
export function UserProject(_ref) {
  var children = _ref.children;
  var _useState = useState(),
    projectInfo = _useState[0],
    setProjectInfo = _useState[1];
  var _useGroupDisclosure = useGroupDisclosure({
      opts: {
        onClose: function onClose() {
          return setProjectInfo(undefined);
        }
      }
    }),
    onModalOpen = _useGroupDisclosure.onOpen,
    isOpenById = _useGroupDisclosure.isOpenById,
    onModalClose = _useGroupDisclosure.onClose;
  var _useUserContext = useUserContext(),
    hasFlag = _useUserContext.hasFlag;
  var handleSuccess = function handleSuccess(message) {
    return addSuccessToast(message, {
      position: 'top-right'
    });
  };
  var handleError = function handleError(err, msg) {
    console.error(err);
    addErrorToast(msg, {
      position: 'top-right'
    });
  };
  var canTag = hasFlag(FEATURE_PROJECT_TAGS);
  var handleModalOpen = useCallback(function (info, id) {
    setProjectInfo(info);
    onModalOpen(id);
  }, [onModalOpen]);
  return __jsx(UserProjectProvider, {
    value: {
      onModalOpen: handleModalOpen,
      onModalClose: onModalClose
    }
  }, children, projectInfo && __jsx(React.Fragment, null, __jsx(ProjectSettingsModal, {
    isOpen: isOpenById('project-settings'),
    onClose: onModalClose,
    projectId: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.id,
    onSuccess: function onSuccess() {
      var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Success!';
      return handleSuccess(msg);
    },
    onError: function onError() {
      var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Something went wrong...';
      return addErrorToast(msg);
    },
    origin: "my-projects"
  }), __jsx(RenameProjectModal, {
    isOpen: isOpenById('rename'),
    onClose: onModalClose,
    projectId: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.id,
    onSuccess: function onSuccess() {
      return handleSuccess('project renamed successfully');
    },
    onError: function onError(err) {
      return handleError(err, 'failed to rename project.');
    }
  }), __jsx(ConfirmDeleteDialog, {
    isOpen: isOpenById('delete'),
    onClose: onModalClose,
    projectId: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.id
  }), __jsx(VersionsDialog, {
    isOpen: isOpenById('versions'),
    onClose: onModalClose,
    projectInfo: projectInfo
  }), canTag && __jsx(TagsDialog, {
    isOpen: isOpenById('tags'),
    onClose: onModalClose,
    projectId: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.id
  })));
}