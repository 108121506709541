import { useCallbackRef } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useUserContext } from '../user/UserContext';
import { useRouter } from './useRouter';

/**
 * When the provided handler is called, it will check `isLoggedIn` or `isAuthenticated`,
 * and if either is true, it will execute the handler.
 *
 * If both are false, it will redirect to the public signup/signin entrypoint.
 */
export function useProtectedHandler(handler) {
  var isAuthenticated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var _useUserContext = useUserContext(),
    isLoggedIn = _useUserContext.isLoggedIn;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath,
    push = _useRouter.push;
  var cachedHandler = useCallbackRef(handler);
  var protectedHandler = useCallback(function () {
    if (isLoggedIn || isAuthenticated) return cachedHandler.apply(void 0, arguments);
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.append('next', asPath);
    var newSearch = searchParams.toString();
    var redirectPath = "/register".concat(newSearch && '?' + newSearch);
    return push(redirectPath);
  }, [cachedHandler, isAuthenticated, isLoggedIn, push, asPath]);
  return {
    handler: protectedHandler
  };
}