import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
export function getTimeAgo(input) {
  var date = input instanceof Date ? input : new Date(input);
  var secondsElapsed = (date.getTime() - Date.now()) / 1000;

  // Only "ago" format if within a year.
  if (Math.abs(secondsElapsed) < 3600 * 24 * 365) {
    var ranges = [['years', 3600 * 24 * 365], ['months', 3600 * 24 * 30], ['weeks', 3600 * 24 * 7], ['days', 3600 * 24], ['hours', 3600], ['minutes', 60], ['seconds', 1]];
    var formatter = new Intl.RelativeTimeFormat('en', {
      style: 'narrow'
    });
    for (var _i = 0, _ranges = ranges; _i < _ranges.length; _i++) {
      var _ranges$_i = _slicedToArray(_ranges[_i], 2),
        rangeType = _ranges$_i[0],
        rangeVal = _ranges$_i[1];
      if (rangeVal < Math.abs(secondsElapsed)) {
        var delta = secondsElapsed / rangeVal;
        return formatter.format(Math.round(delta), rangeType);
      }
    }
  }
  return Intl.DateTimeFormat('en').format(date);
}