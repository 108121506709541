var __jsx = React.createElement;
import { Box, IconButton, CloseIcon, TrashIcon } from '@playful/design_system';
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useBulkMode } from './BulkEditMode.jsx';
import { BulkModeDeleteModal } from './BulkDeleteModal.jsx';
export function BulkModeIndicator() {
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    onOpen = _useDisclosure.onOpen,
    onClose = _useDisclosure.onClose;
  var _useBulkMode = useBulkMode(),
    isBulkModeEnabled = _useBulkMode.isBulkModeEnabled,
    selectedInfos = _useBulkMode.selectedInfos,
    setSelectedInfos = _useBulkMode.setSelectedInfos;
  if (!isBulkModeEnabled || !selectedInfos.size) return null;
  return __jsx(Box, {
    bg: "gray.50",
    padding: 1,
    border: '1px',
    borderColor: 'gray.100',
    borderRadius: "full",
    display: "flex",
    gap: 3,
    alignItems: "center",
    boxShadow: "sm",
    fontSize: "sm"
  }, __jsx(IconButton, {
    variant: "ghost",
    size: "xs",
    rounded: "full",
    "aria-label": "leave bulk mode",
    onClick: function onClick() {
      return setSelectedInfos(new Set());
    },
    icon: __jsx(CloseIcon, null)
  }), __jsx("span", null, selectedInfos.size, " selected"), __jsx(IconButton, {
    variant: "ghost",
    size: "xs",
    rounded: "full",
    "aria-label": "delete projects",
    onClick: onOpen,
    icon: __jsx(TrashIcon, null)
  }), __jsx(BulkModeDeleteModal, {
    isOpen: isOpen,
    onClose: onClose
  }));
}