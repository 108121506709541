import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["dir"],
  _excluded2 = ["observer"],
  _excluded3 = ["onMouseEnter", "onTouchStart", "onFocus", "onMouseLeave", "onTouchEnd", "onBlur"];
var __jsx = React.createElement;
import { Image } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, Box, IconButton, RouteLink, Text, useHatchBreakpoints } from '@playful/design_system';
import React, { useState } from 'react';
import { customEvent, previewTemplateEvent } from '@playful/telemetry';
import { buildUserRoute } from '../../paths.js';
import { TemplateThumbContainer, TemplateThumbMedia, TemplateThumbOverlay, TemplateThumbSkeleton } from './TemplateThumb.jsx';
import { horizBaseStyles, useCarousel, useCarouselItem } from '../../hooks/useCarousel.js';
import { LazyVideoLoader, useLazyVideoRollover } from './LazyVideoLoader.jsx';
import { useThumbLinkQuery } from '../../hooks/useThumbLinkQuery.js';
import { useProjectThumb } from '../../hooks/useProjectThumb.js';
export function TemplateShowcase(_ref) {
  var templates = _ref.templates,
    _ref$projectInfos = _ref.projectInfos,
    projectInfos = _ref$projectInfos === void 0 ? {} : _ref$projectInfos;
  var _useState = useState(null),
    container = _useState[0],
    setContainer = _useState[1];
  var _useCarousel = useCarousel({
      container: container
    }),
    itemProps = _useCarousel.itemProps,
    nextArrowProps = _useCarousel.nextArrowProps,
    prevArrowProps = _useCarousel.prevArrowProps,
    isAtStart = _useCarousel.isAtStart,
    isAtEnd = _useCarousel.isAtEnd,
    isInitializing = _useCarousel.isInitializing;
  return __jsx(Box, {
    display: "grid",
    gridTemplateAreas: "'carousel'",
    maxW: "fit-content"
  }, __jsx(Box, {
    ref: setContainer,
    sx: horizBaseStyles,
    gridArea: 'carousel',
    px: {
      base: 6,
      md: 16
    },
    gap: 10
  }, __jsx(LazyVideoLoader, null, !templates ? Array.from({
    length: 6
  }).map(function (_, i) {
    return __jsx(CarouselItem, _extends({}, itemProps, {
      key: i
    }), __jsx(TemplateThumbSkeleton, {
      aspectRatio: 4 / 3
    }));
  }) : templates.map(function (p) {
    return __jsx(CarouselItem, _extends({}, itemProps, {
      key: p.id
    }), __jsx(TemplateShowcaseItem, {
      info: projectInfos[p.id],
      videoUrl: p.videoUrl,
      posterTs: p.posterTs
    }));
  }))), __jsx(Box, {
    gridArea: 'carousel',
    pointerEvents: 'none',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 3,
    zIndex: 1
  }, __jsx(DirectionalArrow, _extends({
    "aria-label": 'previous template',
    opacity: isAtStart || isInitializing ? 0 : 1,
    right: isAtStart || isInitializing ? 3 : 0,
    icon: __jsx(ArrowLeftIcon, null)
  }, prevArrowProps)), __jsx(DirectionalArrow, _extends({
    "aria-label": 'next template',
    opacity: isAtEnd || isInitializing ? 0 : 1,
    left: isAtEnd || isInitializing ? 3 : 0,
    icon: __jsx(ArrowRightIcon, null)
  }, nextArrowProps))));
}
function DirectionalArrow(_ref2) {
  var dir = _ref2.dir,
    props = _objectWithoutProperties(_ref2, _excluded);
  return __jsx(IconButton, _extends({
    pointerEvents: 'auto',
    borderRadius: 'full',
    colorScheme: 'white',
    boxShadow: 'md',
    pos: "relative",
    transition: 'all 250ms ease-in-out',
    userSelect: 'none'
  }, props));
}
function CarouselItem(_ref3) {
  var observer = _ref3.observer,
    props = _objectWithoutProperties(_ref3, _excluded2);
  var ref = useCarouselItem({
    observer: observer
  });
  return __jsx(Box, _extends({
    ref: ref,
    h: "fit-content",
    w: '304px',
    overflow: 'hidden',
    scrollSnapAlign: 'center'
  }, props));
}
var TemplateShowcaseItem = function TemplateShowcaseItem(_ref4) {
  var info = _ref4.info,
    videoUrl = _ref4.videoUrl,
    posterTs = _ref4.posterTs;
  var _useHatchBreakpoints = useHatchBreakpoints(),
    isSmallScreen = _useHatchBreakpoints.isSmallScreen;
  var linkQuery = useThumbLinkQuery({
    projectId: info.id,
    fallbackData: info
  });
  var _useProjectThumb = useProjectThumb({
      projectId: info.id,
      videoUrl: videoUrl,
      projectInfo: info
    }),
    type = _useProjectThumb.type,
    mediaUrl = _useProjectThumb.mediaUrl,
    _poster = _useProjectThumb.poster;
  // if we go to a small screen, we don't want rollover videos, we want an image
  var mediaSrc = isSmallScreen && type === 'video' ? _poster : mediaUrl;
  var _useLazyVideoRollover = useLazyVideoRollover({
      src: mediaUrl,
      poster: _poster,
      posterTs: posterTs
    }),
    setVideoEl = _useLazyVideoRollover.setVideoEl,
    videoProps = _useLazyVideoRollover.videoProps;
  var onMouseEnter = videoProps.onMouseEnter,
    onTouchStart = videoProps.onTouchStart,
    onFocus = videoProps.onFocus,
    onMouseLeave = videoProps.onMouseLeave,
    onTouchEnd = videoProps.onTouchEnd,
    onBlur = videoProps.onBlur,
    vProps = _objectWithoutProperties(videoProps, _excluded3);
  return __jsx(Box, {
    transition: 'all 300ms ease-in-out',
    w: "100%",
    onMouseEnter: onMouseEnter,
    onTouchStart: onTouchStart,
    onFocus: onFocus,
    onMouseLeave: onMouseLeave,
    onTouchEnd: onTouchEnd,
    onBlur: onBlur
  }, __jsx(RouteLink, {
    href: {
      query: linkQuery
    },
    onClick: function onClick() {
      customEvent('preview-project', {
        project_id: info.id,
        owner: info.owner,
        slug: info.slug
      });
      previewTemplateEvent(info);
    },
    display: 'flex',
    asPath: buildUserRoute(info.ownerName, info.slug),
    shallow: true,
    _hover: {
      textDecoration: 'none'
    },
    flexDir: 'column',
    gap: 4,
    overflow: 'hidden',
    w: "100%"
  }, __jsx(TemplateThumbContainer, null, __jsx(TemplateThumbSkeleton, null), __jsx(TemplateThumbMedia, null, type === 'video' && !isSmallScreen ? __jsx(Box, _extends({
    ref: setVideoEl,
    as: "video"
  }, vProps)) : __jsx(Image, {
    src: mediaSrc,
    alt: info.title,
    objectFit: 'cover',
    objectPosition: 'top',
    loading: 'lazy',
    w: "100%",
    h: "100%"
  })), __jsx(TemplateThumbOverlay, null)), __jsx(Box, {
    color: 'gray.800',
    display: 'flex',
    alignItems: 'center'
  }, __jsx(Text, {
    lineHeight: 'shorter',
    fontWeight: 700,
    transitionDuration: '360ms',
    transitionProperty: 'color',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1
  }, info.title))));
};