import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { createGenericContext } from '@playful/utils';
import React, { useEffect, useState } from 'react';
var _createGenericContext = createGenericContext({
    displayName: 'BulkMode'
  }),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useHook = _createGenericContext2[0],
  BulkModeProvider = _createGenericContext2[1];
export var useBulkMode = useHook;
export function BulkEditMode(_ref) {
  var children = _ref.children;
  var _useState = useState(false),
    isCmdPressed = _useState[0],
    setIsCmdPressed = _useState[1];
  var _useState2 = useState(new Set()),
    selectedInfos = _useState2[0],
    setSelectedInfos = _useState2[1];
  useEffect(function () {
    var leaveBulkMode = function leaveBulkMode() {
      return setIsCmdPressed(false);
    };
    var handleKeyDown = function handleKeyDown(e) {
      if (e.key === 'Meta' || e.key === 'Control') {
        setIsCmdPressed(true);
        return;
      }
      if (e.key === 'Escape') {
        leaveBulkMode();
        // don't cause a re-render if the set is already empty
        setSelectedInfos(function (prev) {
          return prev.size ? new Set() : prev;
        });
        return;
      }
      leaveBulkMode();
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', leaveBulkMode);
    document.addEventListener('mouseleave', leaveBulkMode);
    document.addEventListener('visibilitychange', leaveBulkMode);
    return function () {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', leaveBulkMode);
      document.removeEventListener('mouseleave', leaveBulkMode);
      document.removeEventListener('visibilitychange', leaveBulkMode);
    };
  }, []);
  return __jsx(BulkModeProvider, {
    value: {
      isBulkModeEnabled: isCmdPressed || !!selectedInfos.size,
      selectedInfos: selectedInfos,
      setSelectedInfos: setSelectedInfos
    }
  }, children);
}