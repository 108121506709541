var __jsx = React.createElement;
import { Box } from '@playful/design_system';
import React from 'react';
export function Footer() {
  return __jsx(Box, {
    flexShrink: 0,
    py: 28,
    backgroundColor: 'gray.900',
    display: 'flex',
    justifyContent: 'center'
  }, __jsx(Box, {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column'
  }, __jsx(Box, {
    display: 'flex',
    flexDirection: {
      base: 'column',
      md: 'row'
    },
    gap: {
      base: 2,
      md: 8
    },
    color: 'white',
    fontSize: 'lg',
    textDecoration: 'underline',
    fontWeight: 'bold',
    alignItems: 'center'
  }, __jsx("a", {
    target: '_blank',
    rel: "noreferrer",
    "aria-label": 'About Hatch',
    href: '/about-us'
  }, "About Us"), __jsx("a", {
    target: '_blank',
    rel: "noreferrer",
    "aria-label": 'Hatch Careers',
    href: '/work-here/'
  }, "Work Here"), __jsx("a", {
    href: '/terms-and-conditions/',
    "aria-label": 'Hatch Terms of Use'
  }, "Terms of Use"), __jsx("a", {
    href: '/privacy-policy/',
    "aria-label": 'Hatch Privacy Policy'
  }, "Privacy Policy"))));
}