import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"],
  _excluded2 = ["children"];
var __jsx = React.createElement;
import { Box, useHatchBreakpoints } from '@playful/design_system';
import React from 'react';
import vignette from './assets/light_vignette.svg?url';
import Bolt from './assets/lightning.svg';
export function EmptyProjects(_ref) {
  var children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded);
  var _useHatchBreakpoints = useHatchBreakpoints(),
    isSmallScreen = _useHatchBreakpoints.isSmallScreen;
  return __jsx(Box, _extends({
    display: "grid",
    gridTemplateAreas: "'container'",
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: 'md',
    alignItems: 'center',
    color: 'gray.900',
    pos: "relative"
  }, props), !isSmallScreen && __jsx(React.Fragment, null, __jsx(Box, {
    gridArea: 'container',
    backgroundImage: "url(".concat(vignette, ")"),
    h: '100%',
    w: '447px',
    backgroundPosition: '-58px center',
    backgroundRepeat: 'no-repeat'
    // safari can *not* deal with this being 100% height and not pos absolute.
    ,
    pos: "absolute"
  }), __jsx(Box, {
    gridArea: 'container',
    display: "flex",
    alignItems: 'center',
    maxH: '120px',
    justifyContent: 'center',
    w: '274px',
    zIndex: 1
  }, __jsx(Box, {
    as: Bolt
  }))), children);
}
export function EmptyProjectsContent(_ref2) {
  var children = _ref2.children,
    props = _objectWithoutProperties(_ref2, _excluded2);
  return __jsx(Box, _extends({
    gridArea: 'container',
    py: 8
    // avoids the text from getting too close to the bolt before we go to a sm screen
    ,
    px: {
      base: 0,
      md: '220px'
    },
    textAlign: 'center',
    placeSelf: 'center',
    zIndex: 1
  }, props), children);
}