function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { useMemo, useState } from 'react';
import { useIntersectionObserved, useIntersectionObserver } from './useIntersectionObserver.jsx';
/**
 * Headless carousel using intersection observers.
 * Should work for any size, should be easy to extend with options and orientation.
 */
export function useCarousel(_ref) {
  var container = _ref.container;
  var _useState = useState(false),
    isAtStart = _useState[0],
    setAtStart = _useState[1];
  var _useState2 = useState(false),
    isAtEnd = _useState2[0],
    setAtEnd = _useState2[1];
  var _useState3 = useState(true),
    isInitializing = _useState3[0],
    setInitializing = _useState3[1];
  function handleDir(dir) {
    if (!container) return;
    var items = container.querySelectorAll('.is-visible');
    var visibleItems = Array.from(items);
    var firstVisible = visibleItems[0];
    if (!firstVisible) return;
    var firstVisibleIdx = Array.from(container.children).indexOf(firstVisible);
    var lastVisibleIdx = Array.from(container.children).indexOf(visibleItems[visibleItems.length - 1]);
    var nextIdx = dir === 'next' ? lastVisibleIdx + 1 : firstVisibleIdx - 1;
    var nextItem = container.children[nextIdx] ? container.children[nextIdx] : dir === 'next' ? container.lastElementChild : container.firstElementChild;
    var isAtStart = firstVisibleIdx === 0;
    var isAtEnd = lastVisibleIdx === container.children.length - 1;
    if (isAtStart && dir === 'prev') {
      nextItem = container.lastElementChild;
    }
    if (isAtEnd && dir === 'next') {
      nextItem = container.firstElementChild;
    }
    if (!nextItem) return;
    nextItem.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest'
    });
  }
  var observer = useIntersectionObserver(function (entries) {
    var _iterator = _createForOfIteratorHelper(entries),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var entry = _step.value;
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
        } else {
          entry.target.classList.remove('is-visible');
        }
        if (entry.target === (container === null || container === void 0 ? void 0 : container.firstElementChild)) setAtStart(entry.isIntersecting);
        if (entry.target === (container === null || container === void 0 ? void 0 : container.lastElementChild)) setAtEnd(entry.isIntersecting);
      }

      // on first instersectionObserver run, set initializing to false
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    setInitializing(false);
  }, useMemo(function () {
    return {
      root: container,
      rootMargin: '0px',
      threshold: 0.9
    };
  }, [container]));
  var itemProps = {
    observer: observer
  };
  return {
    isAtStart: isAtStart,
    isAtEnd: isAtEnd,
    nextArrowProps: {
      onClick: function onClick() {
        return handleDir('next');
      }
    },
    prevArrowProps: {
      onClick: function onClick() {
        return handleDir('prev');
      }
    },
    itemProps: itemProps,
    isInitializing: isInitializing
  };
}
export function useCarouselItem(_ref2) {
  var observer = _ref2.observer;
  var _useState4 = useState(null),
    element = _useState4[0],
    setElement = _useState4[1];
  useIntersectionObserved(observer, element);
  return setElement;
}
export var horizBaseStyles = {
  display: 'grid',
  gridAutoFlow: 'column',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};
export var baseItemStyles = {
  scrollSnapAlign: 'center',
  flexShrink: 0
};