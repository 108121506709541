import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from 'react';
import { Box } from '@playful/design_system';
export function TemplateThumbContainer(props) {
  return __jsx(Box, _extends({
    gridTemplateAreas: "'thumbnail'",
    display: 'grid',
    borderRadius: 'md',
    overflow: 'hidden'
  }, props));
}

// we have overflow: hidden on the container which should be enough, but not for safari...
// so we add overflow: hidden here as well, else the media overflows the container
export function TemplateThumbMedia(props) {
  return __jsx(Box, _extends({
    gridArea: 'thumbnail',
    zIndex: 1,
    overflow: 'hidden',
    aspectRatio: 4 / 3
  }, props));
}
export function TemplateThumbSkeleton(props) {
  return __jsx("div", {
    className: "skeleton",
    style: _objectSpread({
      gridArea: 'thumbnail',
      width: '100%',
      height: '100%',
      contentVisibility: 'auto'
    }, props)
  });
}
export function TemplateThumbOverlay(props) {
  return __jsx(Box, _extends({
    gridArea: 'thumbnail',
    zIndex: 1,
    p: 3,
    pointerEvents: 'none',
    h: "100%",
    borderRadius: 'md',
    outline: '1px solid',
    outlineColor: 'blackAlpha.200',
    outlineOffset: '-1px',
    style: {
      contentVisibility: 'auto'
    }
  }, props));
}