var __jsx = React.createElement;
import { useUserProjects } from '../../hooks/useProjects.js';
import { useUserContext } from '../../user/UserContext.jsx';
import React from 'react';
import { MyProjectsGrid } from './MyProjectsGrid.jsx';
import { EmptyProjects, EmptyProjectsContent } from './EmptyProjects.jsx';
import { Button, PlusIcon, Text } from '@playful/design_system';
import { useRouter } from '../../hooks/useRouter.js';
import { ROUTE_TEMPLATES } from '../../routes.js';
export function MyProjects() {
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    isInitializing = _useUserContext.isInitializing;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var _useUserProjects = useUserProjects(user.id),
    projectInfos = _useUserProjects.projectInfos,
    isLoading = _useUserProjects.isLoading;
  if (isInitializing || isLoading) return null;
  if (projectInfos.length) return __jsx(MyProjectsGrid, null);
  return __jsx(EmptyProjects, null, __jsx(EmptyProjectsContent, {
    display: "flex",
    flexDir: 'column',
    gap: 4
  }, __jsx(Text, {
    zIndex: 1
  }, "Your next great design starts here.", __jsx("br", null), " Ready to dive in?"), __jsx(Button, {
    variant: "rebrand-pill",
    colorScheme: "primary",
    leftIcon: __jsx(PlusIcon, null),
    onClick: function onClick() {
      return push({
        query: {
          newProject: true
        },
        pathname: ROUTE_TEMPLATES
      });
    }
  }, "start a new project")));
}