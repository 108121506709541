import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
/**
 * A hook for managing a group of disclosure components.
 *
 * NOTE: this is not the best for transitioning from one modal directly to another.
 * Toggling off the entire modal stack while toggling on the next will cause a flash.
 *
 * For that use case, useSequence is a better bet, as you can use it to replace only the contents
 * of a modal, while still using useDisclosure for the modal itself.
 */
export function useGroupDisclosure(props) {
    const { opts } = props ?? {};
    const [activeId, setActiveId] = useState();
    const { onOpen, onClose, onToggle, isOpen } = useDisclosure(opts);
    function handleClose() {
        // one at a time, plz
        onClose();
        setActiveId(undefined);
    }
    function handleOpen(id) {
        onOpen();
        setActiveId(id);
    }
    const isOpenById = useCallback((id) => {
        return isOpen && activeId === id;
    }, [isOpen, activeId]);
    return {
        onToggle,
        onOpen: handleOpen,
        onClose: handleClose,
        isOpenById,
        activeId,
    };
}
