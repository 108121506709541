import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Box } from '@playful/design_system';
import { useUserProjects } from '../../hooks/useProjects.js';
import { useUserContext } from '../../user/UserContext.jsx';
import { FEATURE_INTERNAL_TOOLS } from '@playful/api';
import React, { useEffect, useState } from 'react';
import { createProjectFromDirectory } from './importProject.js';
import { buildProjectEditRoute } from '../../paths.js';
import { useRouter } from '../../hooks/useRouter.js';
export function MyProjectsDropZone(props) {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasFlag = _useUserContext.hasFlag;
  var _useUserProjects = useUserProjects(user.id, undefined, {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: false
    }),
    newProjectInfo = _useUserProjects.newProjectInfo;
  var _useState = useState(),
    dropFiles = _useState[0],
    setDropFiles = _useState[1];
  var _useState2 = useState(),
    dropFileEntries = _useState2[0],
    setDropFileEntries = _useState2[1];

  // Employees can drag drop exported project directories onto "my projects" to import them.
  useEffect(function () {
    if (dropFileEntries) {
      var fileEntry = dropFileEntries[0];
      if (fileEntry && fileEntry.isDirectory) {
        // Create a project containing all the files in the directory then open it
        // in the Workbench. Special smarts for info.json and project.json files.
        createProjectFromDirectory(fileEntry, newProjectInfo, user).then(function (info) {
          return push(buildProjectEditRoute(info));
        });
      } else {
        // TODO: Make more robust to handle an arbitrary list of files, not just
        // directories.
      }
      setDropFiles(undefined);
      setDropFileEntries(undefined);
    }
  }, [dropFiles, dropFileEntries, newProjectInfo, user, push]);
  var enableProjectImport = hasFlag(FEATURE_INTERNAL_TOOLS);
  function onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }
  function onDrop(event) {
    // Keep URL drops from navigating away.
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      setDropFiles(event.dataTransfer.files);
      // We must extract the file entries immediately because dataTransfer.items.webkitGetAsEntry()
      // contents are cleared after this event is processed.
      var items = [];
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        var item = event.dataTransfer.items[i];
        var getAsEntry = item.getAsEntry || item.webkitGetAsEntry;

        // IE does not support getAsEntry. But do we really support IE?
        // https://caniuse.com/mdn-api_datatransferitem_webkitgetasentry
        if (!getAsEntry) {
          alert('This ancient browser does not support importing projects via drag/drop.');
          return;
        }
        items.push(getAsEntry.call(item));
      }
      setDropFileEntries(items);
    }
  }
  return __jsx(Box, _extends({}, props, {
    onDragOver: enableProjectImport ? onDragOver : undefined,
    onDrop: enableProjectImport ? onDrop : undefined
  }));
}